import logo from "./images/betbet-logo-sq-300.png";
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <img src={logo} alt="BetBet" id="logo"/>
        <video autoPlay loop muted id='video'>
            <source src='https://betsquared-homepage.s3.us-east-2.amazonaws.com/underconstruction.mov' type='video/mp4' />
        </video>
      </header>
    </div>
  );
}

export default App;
